<script setup lang="ts">
import { contactData } from "~/state/state";
const contact = contactData.toObject;
</script>

<template>
    <VRow no-gutters class="text-sm-left">
        <VCol cols="12" class="pb-7">
            <h5 class="mb-2 mb-sm-4 mb-md-5 text-body-1 text-sm-h6 text-md-h5">Address</h5>
            <VRow dense justify="center" justify-sm="start" class="flex-nowrap text-body-2 text-md-body-1 font-weight-light">
                <VCol cols="auto">
                    <VIcon :icon="`$${contact.address.icon}`" :color="contact.address.color.light" />
                </VCol>
                <VCol cols="auto">
                    <NuxtLink
                        :to="contact.address.url"
                        :target="contact.address.target"
                        :title="contact.address.linkTitle"
                        :style="`--color: var(--v-theme-${contact.address.color.light})`"
                    >
                        <span v-html="contact.address.text"> </span>
                    </NuxtLink>
                </VCol>
            </VRow>
        </VCol>
        <VCol cols="12">
            <h5 class="mb-2 mb-sm-4 mb-md-5 text-body-1 text-sm-h6 text-md-h5">Contact</h5>
            <VRow dense justify="center" justify-sm="start" class="flex-nowrap text-body-2 text-md-body-1 font-weight-light">
                <VCol cols="auto">
                    <VIcon :icon="`$${contact.email.icon}`" :color="contact.email.color.light" />
                </VCol>
                <VCol cols="auto">
                    <NuxtLink
                        :to="contact.email.url"
                        :target="contact.email.target"
                        :title="contact.email.linkTitle"
                        :style="`--color: var(--v-theme-${contact.email.color.light})`"
                    >
                        <span v-html="contact.email.text"> </span>
                    </NuxtLink>
                </VCol>
            </VRow>
            <VRow dense justify="center" justify-sm="start" class="flex-nowrap text-body-2 text-md-body-1 font-weight-light">
                <VCol cols="auto">
                    <VIcon :icon="`$${contact.phone.icon}`" :color="contact.phone.color.light" />
                </VCol>
                <VCol cols="auto">
                    <NuxtLink
                        :to="contact.phone.url"
                        :target="contact.phone.target"
                        :title="contact.phone.linkTitle"
                        :style="`--color: var(--v-theme-${contact.phone.color.light})`"
                    >
                        <span v-html="contact.phone.text"> </span>
                    </NuxtLink>
                </VCol>
            </VRow>
        </VCol>
    </VRow>
</template>
