<script setup lang="ts">
import { contactData } from "~/state/state";

const companiesLinks = ["www.allmedica.pl", "www.allestetis.pl", "www.alldental.pl", "www.alldiag.pl", "www.allmedicabadaniakliniczne.pl"];
</script>

<template>
    <VFooter app class="pa-0" style="z-index: 904">
        <VThemeProvider theme="dark" with-background style="width: 100%">
            <SectionWrapper class-name="pb-0" class="footer">
                <VRow class="text-center" justify="center" align-sm="center" align-lg="start">
                    <VCol cols="12" sm="5" md="4" lg="3" class="align-self-lg-center">
                        <VRow no-gutters justify="center">
                            <VCol cols="4" sm="8" class="pb-7 pb-lg-2">
                                <NuxtLink to="/">
                                    <Svg name="allmedica-logo-vertical" role="link" width="100%" color="white"></Svg>
                                </NuxtLink>
                            </VCol>
                            <VCol cols="auto" class="pb-7 d-none d-lg-block">
                                <SocialTrustpilotLink
                                    :to="contactData.trustpilot.link"
                                    :title="contactData.trustpilot.title"
                                    :text="contactData.trustpilot.text"
                                    :icon="contactData.trustpilot.icon"
                                ></SocialTrustpilotLink>
                            </VCol>

                            <VCol cols="12" class="pb-7 d-none d-md-block d-lg-none">
                                <FooterExternalLinks :links="companiesLinks" />
                                <SocialTrustpilotLink
                                    class="justify-center"
                                    :to="contactData.trustpilot.link"
                                    :title="contactData.trustpilot.title"
                                    :text="contactData.trustpilot.text"
                                    :icon="contactData.trustpilot.icon"
                                ></SocialTrustpilotLink>
                            </VCol>

                            <VCol cols="12" class="pb-7 d-md-none">
                                <FooterContact />
                            </VCol>
                        </VRow>
                    </VCol>

                    <VCol cols="12" sm="7" lg="3" class="pb-7 d-md-none d-lg-block">
                        <FooterExternalLinks :links="companiesLinks" class="pb-7" />
                        <SocialWrapper :items="contactData.social" hide-default-title justify="center" class="d-md-none">
                            <template #title="{ title }">
                                <h5 class="mb-2 mb-sm-4 mb-md-5 text-body-1 text-sm-h6 text-md-h5">{{ title }}</h5>
                            </template>
                        </SocialWrapper>
                        <SocialTrustpilotLink
                            :to="contactData.trustpilot.link"
                            :title="contactData.trustpilot.title"
                            :text="contactData.trustpilot.text"
                            :icon="contactData.trustpilot.icon"
                            class="justify-center d-lg-none"
                        ></SocialTrustpilotLink>
                    </VCol>

                    <VCol cols="12" md="4" lg="3" class="pb-7 d-none d-md-block" order-md="last">
                        <FooterContact />
                        <SocialWrapper :items="contactData.social" hide-default-title>
                            <template #title="{ title }">
                                <h5 class="mb-2 mb-sm-4 mb-md-5 mt-8 text-body-1 text-sm-h6 text-md-h5 text-start">{{ title }}</h5>
                            </template>
                        </SocialWrapper>
                    </VCol>

                    <VCol cols="12" md="4" lg="3">
                        <h6 class="mb-2 mb-sm-4 mb-md-5text-body-2 text-sm-body-1 text-md-h5">Quick links</h6>
                        <VRow dense justify="start" class="text-body-2 text-md-body-1 font-weight-light">
                            <VCol md="12">
                                <NuxtLink to="/">Home</NuxtLink>
                            </VCol>
                            <VCol md="12">
                                <NuxtLink to="#specialization">Services</NuxtLink>
                            </VCol>
                            <VCol md="12">
                                <NuxtLink to="/staff">Team</NuxtLink>
                            </VCol>
                            <VCol md="12">
                                <NuxtLink to="/cost">Cost</NuxtLink>
                            </VCol>
                            <VCol md="12">
                                <NuxtLink to="/faq">FAQ</NuxtLink>
                            </VCol>
                            <VCol md="12">
                                <NuxtLink to="/contact">Contact&nbsp;us</NuxtLink>
                            </VCol>
                            <VCol md="12">
                                <NuxtLink to="/gallery">Gallery</NuxtLink>
                            </VCol>
                        </VRow>
                    </VCol>
                </VRow>
                <div class="text-center py-7 px-5 text-disabled">©&nbsp;Allmedica&nbsp;{{ new Date().getFullYear() }}</div>
            </SectionWrapper>
        </VThemeProvider>
    </VFooter>
</template>
<style lang="scss" scoped>
.footer {
    background-image: url("/img/blob-bg.svg"), linear-gradient(0deg, rgba(33, 31, 129, 0.67), rgba(33, 31, 129, 0.67)),
        linear-gradient(90deg, #3666e7 0%, #9265db 55.93%), linear-gradient(90deg, #3666e7 0%, #9265db 55.93%);
    background-repeat: no-repeat;
    background-size: cover;
}
</style>
